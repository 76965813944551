<template>
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}岗位`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="600px"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="岗位名称"
                name="name"
                :rules="[{ required: true, message: '请输入岗位名称' }, { validator: checkName }]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                v-if="Boolean(upDateId)"
                label="岗位编码"
                name="code"
                :rules="[{ required: true, message: '请输入岗位编码' }]"
            >
                <a-input v-model:value="formData.code" disabled />
            </a-form-item>

            <a-form-item
                label="类型"
                name="type"
                :rules="[{ required: true, message: '请选择类型' }]"
            >
                <Select
                    v-model:value="formData.type"
                    :options="typeCandidate"
                    labelKey="value"
                    valueKey="key"
                />
            </a-form-item>

            <a-form-item
                label="岗位描述"
                name="description"
                :rules="[{ validator: checkDescription }]"
            >
                <a-textarea
                    v-model:value="formData.description"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiUpdatePost, apiPostIndexData } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formData: {
                id: "",
                name: "",
                code: "",
                type: "",
                description: "",
            },
            typeCandidate: [],
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let { name } = state.formData;
            state.formData.name = name?.trim();
            let res = await apiUpdatePost(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getPostIndexData = async id => {
            let res = await apiPostIndexData(id);
            if (res.status === "SUCCESS") {
                let { typeCandidate, sysPost } = res.data;
                state.typeCandidate = typeCandidate;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = sysPost ? sysPost[key] : "";
                });
            }
        };

        const checkName = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const checkDescription = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getPostIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkName,
            checkDescription,
        };
    },
});
</script>
